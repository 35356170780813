import React, { useState, useEffect } from "react";
import { styled } from "twin.macro";
import { useLocation } from "@reach/router";
import PinkForm from "./PinkForm";
import TwoPanels from "./TwoPanels";

const GetInvolvedPageWrapper = styled.main`
  position: relative;
`;

const SiteTabs = styled.nav`
  background-color: #ffffff;
  text-align: center;
  filter: drop-shadow(0 5px 12px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;

  padding: 20px;
  position: absolute;
  left: 20px;
  right: 20px;
  top: -75px;

  @media (min-width: 768px) {
    padding: 20px 40px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    left: 64px;
    right: 64px;
    top: -82px;
  }

  @media (min-width: 1024px) {
    left: 80px;
    right: 80px;
    top: -61px;
    padding: 30px;
    justify-content: center;
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const TabTitle = styled.button`
  background-color: transparent;
  border: 0;
  padding: 6px 0;
  text-align: center;
  color: var(--color-grey);
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  transition: all 0.25s ease;

  &.active,
  &:hover {
    color: var(--color-magenta);
    /* border-bottom: 2px solid var(--color-magenta); */
    text-decoration: underline;
  }

  &:focus {
    outline: 1px solid transparent;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.56px;
    text-align: center;
    width: 50%;
    padding: 10px;
  }

  @media (min-width: 1024px) {
    flex: 1;
    line-height: 32px;
    letter-spacing: -0.68px;
  }

  @media (min-width: 1200px) {
    font-size: 24px;
  }
`;

const GetInvolvedPage = ({ children, actions }) => {
  const [currentAction, setCurrentAction] = useState(actions[0]);

  const location = useLocation();

  const handleClickTab = title => {
    const newAction = actions.find(act => act.form.title === title);
    newAction && setCurrentAction(newAction);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    switch (location.hash) {
      case "#donate":
        setCurrentAction(actions.find(act => act.title === "Give a Donation"));
        break;
      case "#sponsor":
        setCurrentAction(actions.find(act => act.title === "Sponsor a Child"));
        break;
      case "#volunteer":
        setCurrentAction(
          actions.find(act => act.title === "Volunteer Your Time")
        );
        break;
      case "#speak":
        setCurrentAction(actions.find(act => act.title === "Speak Up"));
        break;

      default:
        break;
    }
    return () => {};
  }, [location.hash]);
  return (
    <GetInvolvedPageWrapper>
      {actions && (
        <SiteTabs>
          {actions.map((item, index) => (
            <TabTitle
              key={index}
              onClick={() => handleClickTab(item?.form?.title)}
              className={
                item?.form?.title === currentAction.form.title ? "active" : ""
              }
            >
              {item?.title}
            </TabTitle>
          ))}
        </SiteTabs>
      )}
      <PinkForm {...currentAction.form} />
      <TwoPanels
        getInvolved={true}
        left={currentAction?.two_column?.left_content}
        right={currentAction?.two_column?.right_content}
      />
    </GetInvolvedPageWrapper>
  );
};

export default GetInvolvedPage;
