import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/seo";
import PageHero from "../../components/PageSections/PageHero";
import GetInvolvedPage from "../../components/PageSections/GetInvolvedPage";

const GetInvolvedComponent = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "get-involved" } }) {
        nodes {
          title
          template
          slug
          id
          content
          menu_order
          path
          acf {
            hero {
              art {
                source_url
              }
              artist {
                age
                credit
              }
              title
              copy
              cta_type
              cta_text
              cta_link
            }
            action {
              title
              form {
                title
                content
              }
              two_column {
                left_content
                right_content
              }
            }
          }
          yoast_meta {
            yoast_wpseo_social_defaults {
              og_frontpage_image {
                source_url
              }
            }
            yoast_wpseo_metadesc
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_image {
              source_url
            }
            yoast_wpseo_facebook_description
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_image {
              source_url
            }
            yoast_wpseo_twitter_description
          }
        }
      }
    }
  `);
  const getInvolvedData = data.allWordpressPage.nodes[0];

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = getInvolvedData.yoast_meta;

  const seo_image =
    getInvolvedData.yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image
      ?.source_url || "";
  const seo_fb_image =
    getInvolvedData.yoast_meta.yoast_wpseo_facebook_image?.source_url ||
    seo_image;
  const seo_tw_image =
    getInvolvedData.yoast_meta.yoast_wpseo_twitter_image?.source_url ||
    seo_image;

  return (
    <Layout>
      <SEO
        title={getInvolvedData.title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />
      <PageHero {...getInvolvedData?.acf?.hero} getInvolved={true} />
      <GetInvolvedPage actions={getInvolvedData?.acf?.action} />
    </Layout>
  );
};

export default GetInvolvedComponent;
