import React, { useState, useEffect, useContext } from "react";
import tw, { styled } from "twin.macro";
import Context from "../../context/Context";
import Button from "../PageComponents/Button";
import SocialIcon from "../PageComponents/SocialIcon";
import { Link } from "gatsby";

const PinkSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-magenta);
  text-align: center;
  color: #fff;
  ${tw`pt-32 pb-24`}

  @media (min-width: 1024px) {
    min-height: 40rem;
  }
`;

const PinkSectionContent = styled.div`
  position: relative;
  padding-top: 30px;

  @media (min-width: 768px) {
    padding: 0;
  }

  animation-name: fadeIn;
  animation-duration: 0.5s;

  @keyframes fadeIn {
    from {
      top: -10px;
      opacity: 0;
    }

    to {
      top: 0;
      opacity: 1;
    }
  }
`;

const Title = styled.h3`
  font-size: 3.5rem;
  margin-bottom: 0.5rem;
  line-height: 1;

  @media (min-width: 640px) {
    font-size: 4.5rem;
  }

  @media (min-width: 768px) {
    font-size: 5rem;
  }

  @media (min-width: 1024px) {
    font-size: 5.5rem;
  }
`;

const SubTitle = styled.p`
  font-weight: 600;

  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.17px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.71px;
    margin-bottom: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 38px;
    line-height: 48px;
    letter-spacing: -0.37px;
    margin-bottom: 40px;
  }
`;

const GetInvolvedButtonTitle = styled.p`
  font-weight: 600;

  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.56px;
    margin-bottom: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 27px;
    line-height: 32px;
    letter-spacing: -0.68px;
    margin-bottom: 40px;
  }
`;

const ButtonRow = styled.aside`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 -0.5rem;
`;

const GetInvolvedButton = styled.button`
  text-transform: uppercase;
  font-family: attribute-mono, monospace;
  border: 1.2px solid #fff;
  color: #fff;
  background-color: transparent;
  transition: background-color 0.25s ease, color 0.25s ease;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: var(--color-magenta);
  }

  width: 60px;
  height: 30px;
  border-radius: 19.73px;
  font-size: 11px;
  letter-spacing: 0.25px;
  margin: 0.5rem;

  @media (min-width: 768px) {
    width: 140px;
    height: 40px;
    border-radius: 26.31px;
    font-size: 15px;
    letter-spacing: 0.34px;
  }

  @media (min-width: 1024px) {
    width: 200px;
    height: 50px;
    border-radius: 32.89px;
    font-size: 19px;
    letter-spacing: 0.5px;
    border: 2px solid;
  }
`;

const GetInvolvedSocial = styled(SocialIcon)`
  width: 60px;
  height: 30px;
  border-radius: 19.73px;
  font-size: 11px;
  letter-spacing: 0.25px;
  svg {
    fill: transparent;
  }

  @media (min-width: 768px) {
    width: 140px;
    height: 40px;
    border-radius: 26.31px;
    font-size: 15px;
    letter-spacing: 0.34px;
  }

  @media (min-width: 1024px) {
    margin: 0 15px;
    width: 200px;
    height: 50px;
    border-radius: 32.89px;
    font-size: 19px;
    letter-spacing: 0.5px;
    border: 2px solid;
  }
`;

// const DonateOther = styled.form`
//   margin-top: 20px;
//   &:before {
//     content: "$";
//     border-bottom: 1px solid #fff;
//     color: #fff;
//     font-family: attribute-mono, monospace;
//   }
// `;

// const DonateOtherInput = styled.input`
//   border: 0;
//   padding-left: 1ch;
//   border-bottom: 1px solid #fff;
//   background-color: transparent;
//   color: #fff;
//   font-family: attribute-mono, monospace;

//   &::placeholder {
//     color: #fff;
//     opacity: 0.6;
//   }
// `;

const Submit = styled.button``;

const LearnMore = styled(Button)`
  margin-top: 55px;
`;

const Content = styled.div`
  text-align: center;

  h2,
  h4 {
    font-weight: 600;

    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.17px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 34px;
      letter-spacing: -0.71px;
      margin-bottom: 30px;
    }

    @media (min-width: 1024px) {
      font-size: 38px;
      line-height: 48px;
      letter-spacing: -0.37px;
      margin-bottom: 40px;
    }
  }

  p {
    font-weight: 600;

    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.4px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.56px;
      margin-bottom: 30px;
    }

    @media (min-width: 1024px) {
      font-size: 27px;
      line-height: 32px;
      letter-spacing: -0.68px;
      margin-bottom: 40px;
    }
  }

  /* a {
    margin-top: 20px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: attribute-mono, monospace;
    padding: 8px 20px;
    border-radius: 32.89px;
    background-color: transparent;
    color: var(--color-offWhite);
    border: 2.27px solid var(--color-offWhite);
    transition: background-color 0.5s ease, color 0.5s ease, filter 0.5s ease;

    &:hover {
      background-color: var(--color-offWhite);
      color: var(--color-offBlack);
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    }

    @media (min-width: 768px) {
      font-size: 15px;
      letter-spacing: 0.34px;
      padding: 10px 25px;
    }

    @media (min-width: 1024px) {
      font-size: 19px;
      letter-spacing: 0.43px;
      height: auto;
      padding: 11.5px 31.5px;
    }
  } */
`;

const DonateButton = styled.button`
  font-family: attribute-mono, monospace;
  text-align: center;
  cursor: pointer;
  ${tw`
    text-white border-0 px-8 py-6 rounded-full
  `}

  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #fff;
  line-height: 1;
`;

const PinkForm = ({ title, content }) => {
  const [formType, setFormType] = useState(null);
  const { setModal } = useContext(Context);

  const renderSection = () => {
    switch (formType) {
      case "donate":
        return (
          <>
            <SubTitle dangerouslySetInnerHTML={{ __html: content }} />
            <Button style="light">
              <a href="https://eachstephome.donorsupport.co/-/XYWQRRQB">
                Give Today
              </a>
            </Button>
            {/* {other && (
            <DonateOther>
              <DonateOtherInput
                type="number"
                name="other"
                id="other"
                placeholder="Enter Amount"
              />
              <Submit
                onClick={e => {
                  e.preventDefault();
                  const value = document.getElementById("other").value;
                  handleClickDonate(value);
                }}
              >
                Submit
              </Submit>
            </DonateOther>
          )} */}
            {/* <LearnMore style={"donate_learn_more"} className="learn-more">
            {" "}
            Learn More{" "}
          </LearnMore> */}
          </>
        );
        break;
      case "sponsor":
        return (
          <>
            <Content dangerouslySetInnerHTML={{ __html: content }} />
            <Button style="light">
              <Link to="/get-involved/sponsor/">Learn More</Link>
            </Button>
          </>
        );
        break;
      case "volunteer":
        return (
          <>
            <Content dangerouslySetInnerHTML={{ __html: content }} />
            <Button
              style="light"
              onClick={() => setModal(true, "volunteer", null)}
            >
              Sign Up
            </Button>
          </>
        );
        break;
      case "speak up":
        return (
          <>
            <Content dangerouslySetInnerHTML={{ __html: content }} />
            <ButtonRow>
              <GetInvolvedSocial icon="facebook" large={true} />
              <GetInvolvedSocial icon="instagram" large={true} />
              <GetInvolvedSocial icon="twitter" large={true} />
            </ButtonRow>
          </>
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setFormType(title.toLowerCase());
    return () => {};
  }, [title]);

  return (
    <PinkSection id="donate">
      <PinkSectionContent key={title}>
        <div className="container">
          <Title>{title}</Title>
          {renderSection()}
        </div>
      </PinkSectionContent>
    </PinkSection>
  );
};

export default PinkForm;
